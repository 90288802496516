import request from '@/utils/request'

export function getIconList(params) {
  const List = [
    '24-hours-fill',
    '24-hours-line',
    '4k-fill',
    '4k-line',
    'a-b',
    'account-box-fill',
    'account-box-line',
    'account-circle-fill',
    'account-circle-line',
    'account-pin-box-fill',
    'account-pin-box-line',
    'account-pin-circle-fill',
    'account-pin-circle-line',
    'add-box-fill',
    'add-box-line',
    'add-circle-fill',
    'add-circle-line',
    'add-fill',
    'add-line',
    'admin-fill',
    'admin-line',
    /* "advertisement-fill",
    "advertisement-line", */
    'airplay-fill',
    'airplay-line',
    'alarm-fill',
    'alarm-line',
    'alarm-warning-fill',
    'alarm-warning-line',
    'album-fill',
    'album-line',
    'alert-fill',
    'alert-line',
    'aliens-fill',
    'aliens-line',
    'align-bottom',
    'align-center',
    'align-justify',
    'align-left',
    'align-right',
    'align-top',
    'align-vertically',
    'alipay-fill',
    'alipay-line',
    'amazon-fill',
    'amazon-line',
    'anchor-fill',
    'anchor-line',
    'ancient-gate-fill',
    'ancient-gate-line',
    'ancient-pavilion-fill',
    'ancient-pavilion-line',
    'android-fill',
    'android-line',
    'angularjs-fill',
    'angularjs-line',
    'anticlockwise-2-fill',
    'anticlockwise-2-line',
    'anticlockwise-fill',
    'anticlockwise-line',
    'app-store-fill',
    'app-store-line',
    'apple-fill',
    'apple-line',
    'apps-2-fill',
    'apps-2-line',
    'apps-fill',
    'apps-line',
    'archive-drawer-fill',
    'archive-drawer-line',
    'archive-fill',
    'archive-line',
    'arrow-down-circle-fill',
    'arrow-down-circle-line',
    'arrow-down-fill',
    'arrow-down-line',
    'arrow-down-s-fill',
    'arrow-down-s-line',
    'arrow-drop-down-fill',
    'arrow-drop-down-line',
    'arrow-drop-left-fill',
    'arrow-drop-left-line',
    'arrow-drop-right-fill',
    'arrow-drop-right-line',
    'arrow-drop-up-fill',
    'arrow-drop-up-line',
    'arrow-go-back-fill',
    'arrow-go-back-line',
    'arrow-go-forward-fill',
    'arrow-go-forward-line',
    'arrow-left-circle-fill',
    'arrow-left-circle-line',
    'arrow-left-down-fill',
    'arrow-left-down-line',
    'arrow-left-fill',
    'arrow-left-line',
    'arrow-left-right-fill',
    'arrow-left-right-line',
    'arrow-left-s-fill',
    'arrow-left-s-line',
    'arrow-left-up-fill',
    'arrow-left-up-line',
    'arrow-right-circle-fill',
    'arrow-right-circle-line',
    'arrow-right-down-fill',
    'arrow-right-down-line',
    'arrow-right-fill',
    'arrow-right-line',
    'arrow-right-s-fill',
    'arrow-right-s-line',
    'arrow-right-up-fill',
    'arrow-right-up-line',
    'arrow-up-circle-fill',
    'arrow-up-circle-line',
    'arrow-up-down-fill',
    'arrow-up-down-line',
    'arrow-up-fill',
    'arrow-up-line',
    'arrow-up-s-fill',
    'arrow-up-s-line',
    'artboard-2-fill',
    'artboard-2-line',
    'artboard-fill',
    'artboard-line',
    'article-fill',
    'article-line',
    'aspect-ratio-fill',
    'aspect-ratio-line',
    'asterisk',
    'at-fill',
    'at-line',
    'attachment-2',
    'attachment-fill',
    'attachment-line',
    'auction-fill',
    'auction-line',
    'award-fill',
    'award-line',
    'baidu-fill',
    'baidu-line',
    'ball-pen-fill',
    'ball-pen-line',
    'bank-card-2-fill',
    'bank-card-2-line',
    'bank-card-fill',
    'bank-card-line',
    'bank-fill',
    'bank-line',
    'bar-chart-2-fill',
    'bar-chart-2-line',
    'bar-chart-box-fill',
    'bar-chart-box-line',
    'bar-chart-fill',
    'bar-chart-grouped-fill',
    'bar-chart-grouped-line',
    'bar-chart-horizontal-fill',
    'bar-chart-horizontal-line',
    'bar-chart-line',
    'barcode-box-fill',
    'barcode-box-line',
    'barcode-fill',
    'barcode-line',
    'barricade-fill',
    'barricade-line',
    'base-station-fill',
    'base-station-line',
    'basketball-fill',
    'basketball-line',
    'battery-2-charge-fill',
    'battery-2-charge-line',
    'battery-2-fill',
    'battery-2-line',
    'battery-charge-fill',
    'battery-charge-line',
    'battery-fill',
    'battery-line',
    'battery-low-fill',
    'battery-low-line',
    'battery-saver-fill',
    'battery-saver-line',
    'battery-share-fill',
    'battery-share-line',
    'bear-smile-fill',
    'bear-smile-line',
    'behance-fill',
    'behance-line',
    'bell-fill',
    'bell-line',
    'bike-fill',
    'bike-line',
    'bilibili-fill',
    'bilibili-line',
    'bill-fill',
    'bill-line',
    'billiards-fill',
    'billiards-line',
    'bit-coin-fill',
    'bit-coin-line',
    'blaze-fill',
    'blaze-line',
    'bluetooth-connect-fill',
    'bluetooth-connect-line',
    'bluetooth-fill',
    'bluetooth-line',
    'blur-off-fill',
    'blur-off-line',
    'body-scan-fill',
    'body-scan-line',
    'bold',
    'book-2-fill',
    'book-2-line',
    'book-3-fill',
    'book-3-line',
    'book-fill',
    'book-line',
    'book-mark-fill',
    'book-mark-line',
    'book-open-fill',
    'book-open-line',
    'book-read-fill',
    'book-read-line',
    'booklet-fill',
    'booklet-line',
    'bookmark-2-fill',
    'bookmark-2-line',
    'bookmark-3-fill',
    'bookmark-3-line',
    'bookmark-fill',
    'bookmark-line',
    'boxing-fill',
    'boxing-line',
    'braces-fill',
    'braces-line',
    'brackets-fill',
    'brackets-line',
    'briefcase-2-fill',
    'briefcase-2-line',
    'briefcase-3-fill',
    'briefcase-3-line',
    'briefcase-4-fill',
    'briefcase-4-line',
    'briefcase-5-fill',
    'briefcase-5-line',
    'briefcase-fill',
    'briefcase-line',
    'bring-forward',
    'bring-to-front',
    'broadcast-fill',
    'broadcast-line',
    'brush-2-fill',
    'brush-2-line',
    'brush-3-fill',
    'brush-3-line',
    'brush-4-fill',
    'brush-4-line',
    'brush-fill',
    'brush-line',
    'bubble-chart-fill',
    'bubble-chart-line',
    'bug-2-fill',
    'bug-2-line',
    'bug-fill',
    'bug-line',
    'building-2-fill',
    'building-2-line',
    'building-3-fill',
    'building-3-line',
    'building-4-fill',
    'building-4-line',
    'building-fill',
    'building-line',
    'bus-2-fill',
    'bus-2-line',
    'bus-fill',
    'bus-line',
    'bus-wifi-fill',
    'bus-wifi-line',
    'cactus-fill',
    'cactus-line',
    'cake-2-fill',
    'cake-2-line',
    'cake-3-fill',
    'cake-3-line',
    'cake-fill',
    'cake-line',
    'calculator-fill',
    'calculator-line',
    'calendar-2-fill',
    'calendar-2-line',
    'calendar-check-fill',
    'calendar-check-line',
    'calendar-event-fill',
    'calendar-event-line',
    'calendar-fill',
    'calendar-line',
    'calendar-todo-fill',
    'calendar-todo-line',
    'camera-2-fill',
    'camera-2-line',
    'camera-3-fill',
    'camera-3-line',
    'camera-fill',
    'camera-lens-fill',
    'camera-lens-line',
    'camera-line',
    'camera-off-fill',
    'camera-off-line',
    'camera-switch-fill',
    'camera-switch-line',
    'capsule-fill',
    'capsule-line',
    'car-fill',
    'car-line',
    'car-washing-fill',
    'car-washing-line',
    'caravan-fill',
    'caravan-line',
    'cast-fill',
    'cast-line',
    'cellphone-fill',
    'cellphone-line',
    'celsius-fill',
    'celsius-line',
    'centos-fill',
    'centos-line',
    'character-recognition-fill',
    'character-recognition-line',
    'charging-pile-2-fill',
    'charging-pile-2-line',
    'charging-pile-fill',
    'charging-pile-line',
    'chat-1-fill',
    'chat-1-line',
    'chat-2-fill',
    'chat-2-line',
    'chat-3-fill',
    'chat-3-line',
    'chat-4-fill',
    'chat-4-line',
    'chat-check-fill',
    'chat-check-line',
    'chat-delete-fill',
    'chat-delete-line',
    'chat-download-fill',
    'chat-download-line',
    'chat-follow-up-fill',
    'chat-follow-up-line',
    'chat-forward-fill',
    'chat-forward-line',
    'chat-heart-fill',
    'chat-heart-line',
    'chat-history-fill',
    'chat-history-line',
    'chat-new-fill',
    'chat-new-line',
    'chat-off-fill',
    'chat-off-line',
    'chat-poll-fill',
    'chat-poll-line',
    'chat-private-fill',
    'chat-private-line',
    'chat-quote-fill',
    'chat-quote-line',
    'chat-settings-fill',
    'chat-settings-line',
    'chat-smile-2-fill',
    'chat-smile-2-line',
    'chat-smile-3-fill',
    'chat-smile-3-line',
    'chat-smile-fill',
    'chat-smile-line',
    'chat-upload-fill',
    'chat-upload-line',
    'chat-voice-fill',
    'chat-voice-line',
    'check-double-fill',
    'check-double-line',
    'check-fill',
    'check-line',
    'checkbox-blank-circle-fill',
    'checkbox-blank-circle-line',
    'checkbox-blank-fill',
    'checkbox-blank-line',
    'checkbox-circle-fill',
    'checkbox-circle-line',
    'checkbox-fill',
    'checkbox-indeterminate-fill',
    'checkbox-indeterminate-line',
    'checkbox-line',
    'checkbox-multiple-blank-fill',
    'checkbox-multiple-blank-line',
    'checkbox-multiple-fill',
    'checkbox-multiple-line',
    'china-railway-fill',
    'china-railway-line',
    'chrome-fill',
    'chrome-line',
    'clapperboard-fill',
    'clapperboard-line',
    'clipboard-fill',
    'clipboard-line',
    'clockwise-2-fill',
    'clockwise-2-line',
    'clockwise-fill',
    'clockwise-line',
    'close-circle-fill',
    'close-circle-line',
    'close-fill',
    'close-line',
    'closed-captioning-fill',
    'closed-captioning-line',
    'cloud-fill',
    'cloud-line',
    'cloud-off-fill',
    'cloud-off-line',
    'cloud-windy-fill',
    'cloud-windy-line',
    'cloudy-2-fill',
    'cloudy-2-line',
    'cloudy-fill',
    'cloudy-line',
    'code-box-fill',
    'code-box-line',
    'code-fill',
    'code-line',
    'code-s-fill',
    'code-s-line',
    'code-s-slash-fill',
    'code-s-slash-line',
    'code-view',
    'codepen-fill',
    'codepen-line',
    'coin-fill',
    'coin-line',
    'coins-fill',
    'coins-line',
    'collage-fill',
    'collage-line',
    'command-fill',
    'command-line',
    'community-fill',
    'community-line',
    'compass-2-fill',
    'compass-2-line',
    'compass-3-fill',
    'compass-3-line',
    'compass-4-fill',
    'compass-4-line',
    'compass-discover-fill',
    'compass-discover-line',
    'compass-fill',
    'compass-line',
    'compasses-2-fill',
    'compasses-2-line',
    'compasses-fill',
    'compasses-line',
    'computer-fill',
    'computer-line',
    'contacts-book-2-fill',
    'contacts-book-2-line',
    'contacts-book-fill',
    'contacts-book-line',
    'contacts-book-upload-fill',
    'contacts-book-upload-line',
    'contacts-fill',
    'contacts-line',
    'contrast-2-fill',
    'contrast-2-line',
    'contrast-drop-2-fill',
    'contrast-drop-2-line',
    'contrast-drop-fill',
    'contrast-drop-line',
    'contrast-fill',
    'contrast-line',
    'copper-coin-fill',
    'copper-coin-line',
    'copper-diamond-fill',
    'copper-diamond-line',
    'copyleft-fill',
    'copyleft-line',
    'copyright-fill',
    'copyright-line',
    'coreos-fill',
    'coreos-line',
    'coupon-2-fill',
    'coupon-2-line',
    'coupon-3-fill',
    'coupon-3-line',
    'coupon-4-fill',
    'coupon-4-line',
    'coupon-5-fill',
    'coupon-5-line',
    'coupon-fill',
    'coupon-line',
    'cpu-fill',
    'cpu-line',
    'creative-commons-by-fill',
    'creative-commons-by-line',
    'creative-commons-fill',
    'creative-commons-line',
    'creative-commons-nc-fill',
    'creative-commons-nc-line',
    'creative-commons-nd-fill',
    'creative-commons-nd-line',
    'creative-commons-sa-fill',
    'creative-commons-sa-line',
    'creative-commons-zero-fill',
    'creative-commons-zero-line',
    'criminal-fill',
    'criminal-line',
    'crop-2-fill',
    'crop-2-line',
    'crop-fill',
    'crop-line',
    'css3-fill',
    'css3-line',
    'cup-fill',
    'cup-line',
    'currency-fill',
    'currency-line',
    'cursor-fill',
    'cursor-line',
    'customer-service-2-fill',
    'customer-service-2-line',
    'customer-service-fill',
    'customer-service-line',
    'dashboard-2-fill',
    'dashboard-2-line',
    'dashboard-3-fill',
    'dashboard-3-line',
    'dashboard-fill',
    'dashboard-line',
    'database-2-fill',
    'database-2-line',
    'database-fill',
    'database-line',
    'delete-back-2-fill',
    'delete-back-2-line',
    'delete-back-fill',
    'delete-back-line',
    'delete-bin-2-fill',
    'delete-bin-2-line',
    'delete-bin-3-fill',
    'delete-bin-3-line',
    'delete-bin-4-fill',
    'delete-bin-4-line',
    'delete-bin-5-fill',
    'delete-bin-5-line',
    'delete-bin-6-fill',
    'delete-bin-6-line',
    'delete-bin-7-fill',
    'delete-bin-7-line',
    'delete-bin-fill',
    'delete-bin-line',
    'delete-column',
    'delete-row',
    'device-fill',
    'device-line',
    'device-recover-fill',
    'device-recover-line',
    'dingding-fill',
    'dingding-line',
    'direction-fill',
    'direction-line',
    'disc-fill',
    'disc-line',
    'discord-fill',
    'discord-line',
    'discuss-fill',
    'discuss-line',
    'dislike-fill',
    'dislike-line',
    'disqus-fill',
    'disqus-line',
    'divide-fill',
    'divide-line',
    'donut-chart-fill',
    'donut-chart-line',
    'door-closed-fill',
    'door-closed-line',
    'door-fill',
    'door-line',
    'door-lock-box-fill',
    'door-lock-box-line',
    'door-lock-fill',
    'door-lock-line',
    'door-open-fill',
    'door-open-line',
    'dossier-fill',
    'dossier-line',
    'douban-fill',
    'douban-line',
    'double-quotes-l',
    'double-quotes-r',
    'download-2-fill',
    'download-2-line',
    'download-cloud-2-fill',
    'download-cloud-2-line',
    'download-cloud-fill',
    'download-cloud-line',
    'download-fill',
    'download-line',
    'draft-fill',
    'draft-line',
    'drag-drop-fill',
    'drag-drop-line',
    'drag-move-2-fill',
    'drag-move-2-line',
    'drag-move-fill',
    'drag-move-line',
    'dribbble-fill',
    'dribbble-line',
    'drive-fill',
    'drive-line',
    'drizzle-fill',
    'drizzle-line',
    'drop-fill',
    'drop-line',
    'dropbox-fill',
    'dropbox-line',
    'dual-sim-1-fill',
    'dual-sim-1-line',
    'dual-sim-2-fill',
    'dual-sim-2-line',
    'dv-fill',
    'dv-line',
    'dvd-fill',
    'dvd-line',
    'e-bike-2-fill',
    'e-bike-2-line',
    'e-bike-fill',
    'e-bike-line',
    'earth-fill',
    'earth-line',
    'earthquake-fill',
    'earthquake-line',
    'edge-fill',
    'edge-line',
    'edit-2-fill',
    'edit-2-line',
    'edit-box-fill',
    'edit-box-line',
    'edit-circle-fill',
    'edit-circle-line',
    'edit-fill',
    'edit-line',
    'eject-fill',
    'eject-line',
    'emotion-2-fill',
    'emotion-2-line',
    'emotion-fill',
    'emotion-happy-fill',
    'emotion-happy-line',
    'emotion-laugh-fill',
    'emotion-laugh-line',
    'emotion-line',
    'emotion-normal-fill',
    'emotion-normal-line',
    'emotion-sad-fill',
    'emotion-sad-line',
    'emotion-unhappy-fill',
    'emotion-unhappy-line',
    'empathize-fill',
    'empathize-line',
    'emphasis-cn',
    'emphasis',
    'english-input',
    'equalizer-fill',
    'equalizer-line',
    'eraser-fill',
    'eraser-line',
    'error-warning-fill',
    'error-warning-line',
    'evernote-fill',
    'evernote-line',
    'exchange-box-fill',
    'exchange-box-line',
    'exchange-cny-fill',
    'exchange-cny-line',
    'exchange-dollar-fill',
    'exchange-dollar-line',
    'exchange-fill',
    'exchange-funds-fill',
    'exchange-funds-line',
    'exchange-line',
    'external-link-fill',
    'external-link-line',
    'eye-2-fill',
    'eye-2-line',
    'eye-close-fill',
    'eye-close-line',
    'eye-fill',
    'eye-line',
    'eye-off-fill',
    'eye-off-line',
    'facebook-box-fill',
    'facebook-box-line',
    'facebook-circle-fill',
    'facebook-circle-line',
    'facebook-fill',
    'facebook-line',
    'fahrenheit-fill',
    'fahrenheit-line',
    'feedback-fill',
    'feedback-line',
    'file-2-fill',
    'file-2-line',
    'file-3-fill',
    'file-3-line',
    'file-4-fill',
    'file-4-line',
    'file-add-fill',
    'file-add-line',
    'file-chart-2-fill',
    'file-chart-2-line',
    'file-chart-fill',
    'file-chart-line',
    'file-cloud-fill',
    'file-cloud-line',
    'file-code-fill',
    'file-code-line',
    'file-copy-2-fill',
    'file-copy-2-line',
    'file-copy-fill',
    'file-copy-line',
    'file-damage-fill',
    'file-damage-line',
    'file-download-fill',
    'file-download-line',
    'file-edit-fill',
    'file-edit-line',
    'file-excel-2-fill',
    'file-excel-2-line',
    'file-excel-fill',
    'file-excel-line',
    'file-fill',
    'file-forbid-fill',
    'file-forbid-line',
    'file-gif-fill',
    'file-gif-line',
    'file-history-fill',
    'file-history-line',
    'file-hwp-fill',
    'file-hwp-line',
    'file-info-fill',
    'file-info-line',
    'file-line',
    'file-list-2-fill',
    'file-list-2-line',
    'file-list-3-fill',
    'file-list-3-line',
    'file-list-fill',
    'file-list-line',
    'file-lock-fill',
    'file-lock-line',
    'file-mark-fill',
    'file-mark-line',
    'file-music-fill',
    'file-music-line',
    'file-paper-2-fill',
    'file-paper-2-line',
    'file-paper-fill',
    'file-paper-line',
    'file-pdf-fill',
    'file-pdf-line',
    'file-ppt-2-fill',
    'file-ppt-2-line',
    'file-ppt-fill',
    'file-ppt-line',
    'file-reduce-fill',
    'file-reduce-line',
    'file-search-fill',
    'file-search-line',
    'file-settings-fill',
    'file-settings-line',
    'file-shield-2-fill',
    'file-shield-2-line',
    'file-shield-fill',
    'file-shield-line',
    'file-shred-fill',
    'file-shred-line',
    'file-text-fill',
    'file-text-line',
    'file-transfer-fill',
    'file-transfer-line',
    'file-unknow-fill',
    'file-unknow-line',
    'file-upload-fill',
    'file-upload-line',
    'file-user-fill',
    'file-user-line',
    'file-warning-fill',
    'file-warning-line',
    'file-word-2-fill',
    'file-word-2-line',
    'file-word-fill',
    'file-word-line',
    'file-zip-fill',
    'file-zip-line',
    'film-fill',
    'film-line',
    'filter-2-fill',
    'filter-2-line',
    'filter-3-fill',
    'filter-3-line',
    'filter-fill',
    'filter-line',
    'filter-off-fill',
    'filter-off-line',
    'find-replace-fill',
    'find-replace-line',
    'finder-fill',
    'finder-line',
    'fingerprint-2-fill',
    'fingerprint-2-line',
    'fingerprint-fill',
    'fingerprint-line',
    'fire-fill',
    'fire-line',
    'firefox-fill',
    'firefox-line',
    'first-aid-kit-fill',
    'first-aid-kit-line',
    'flag-2-fill',
    'flag-2-line',
    'flag-fill',
    'flag-line',
    'flashlight-fill',
    'flashlight-line',
    'flask-fill',
    'flask-line',
    'flight-land-fill',
    'flight-land-line',
    'flight-takeoff-fill',
    'flight-takeoff-line',
    'flood-fill',
    'flood-line',
    'flow-chart',
    'flutter-fill',
    'flutter-line',
    'focus-2-fill',
    'focus-2-line',
    'focus-3-fill',
    'focus-3-line',
    'focus-fill',
    'focus-line',
    'foggy-fill',
    'foggy-line',
    'folder-2-fill',
    'folder-2-line',
    'folder-3-fill',
    'folder-3-line',
    'folder-4-fill',
    'folder-4-line',
    'folder-5-fill',
    'folder-5-line',
    'folder-add-fill',
    'folder-add-line',
    'folder-chart-2-fill',
    'folder-chart-2-line',
    'folder-chart-fill',
    'folder-chart-line',
    'folder-download-fill',
    'folder-download-line',
    'folder-fill',
    'folder-forbid-fill',
    'folder-forbid-line',
    'folder-history-fill',
    'folder-history-line',
    'folder-info-fill',
    'folder-info-line',
    'folder-keyhole-fill',
    'folder-keyhole-line',
    'folder-line',
    'folder-lock-fill',
    'folder-lock-line',
    'folder-music-fill',
    'folder-music-line',
    'folder-open-fill',
    'folder-open-line',
    'folder-received-fill',
    'folder-received-line',
    'folder-reduce-fill',
    'folder-reduce-line',
    'folder-settings-fill',
    'folder-settings-line',
    'folder-shared-fill',
    'folder-shared-line',
    'folder-shield-2-fill',
    'folder-shield-2-line',
    'folder-shield-fill',
    'folder-shield-line',
    'folder-transfer-fill',
    'folder-transfer-line',
    'folder-unknow-fill',
    'folder-unknow-line',
    'folder-upload-fill',
    'folder-upload-line',
    'folder-user-fill',
    'folder-user-line',
    'folder-warning-fill',
    'folder-warning-line',
    'folder-zip-fill',
    'folder-zip-line',
    'folders-fill',
    'folders-line',
    'font-color',
    'font-size-2',
    'font-size',
    'football-fill',
    'football-line',
    'footprint-fill',
    'footprint-line',
    'forbid-2-fill',
    'forbid-2-line',
    'forbid-fill',
    'forbid-line',
    'format-clear',
    'fridge-fill',
    'fridge-line',
    'fullscreen-exit-fill',
    'fullscreen-exit-line',
    'fullscreen-fill',
    'fullscreen-line',
    'function-fill',
    'function-line',
    'functions',
    'funds-box-fill',
    'funds-box-line',
    'funds-fill',
    'funds-line',
    'gallery-fill',
    'gallery-line',
    'gallery-upload-fill',
    'gallery-upload-line',
    'game-fill',
    'game-line',
    'gamepad-fill',
    'gamepad-line',
    'gas-station-fill',
    'gas-station-line',
    'gatsby-fill',
    'gatsby-line',
    'genderless-fill',
    'genderless-line',
    'ghost-2-fill',
    'ghost-2-line',
    'ghost-fill',
    'ghost-line',
    'ghost-smile-fill',
    'ghost-smile-line',
    'gift-2-fill',
    'gift-2-line',
    'gift-fill',
    'gift-line',
    'git-branch-fill',
    'git-branch-line',
    'git-commit-fill',
    'git-commit-line',
    'git-merge-fill',
    'git-merge-line',
    'git-pull-request-fill',
    'git-pull-request-line',
    'git-repository-commits-fill',
    'git-repository-commits-line',
    'git-repository-fill',
    'git-repository-line',
    'git-repository-private-fill',
    'git-repository-private-line',
    'github-fill',
    'github-line',
    'gitlab-fill',
    'gitlab-line',
    'global-fill',
    'global-line',
    'globe-fill',
    'globe-line',
    'goblet-fill',
    'goblet-line',
    'google-fill',
    'google-line',
    'google-play-fill',
    'google-play-line',
    'government-fill',
    'government-line',
    'gps-fill',
    'gps-line',
    'gradienter-fill',
    'gradienter-line',
    'grid-fill',
    'grid-line',
    'group-2-fill',
    'group-2-line',
    'group-fill',
    'group-line',
    'guide-fill',
    'guide-line',
    'h-1',
    'h-2',
    'h-3',
    'h-4',
    'h-5',
    'h-6',
    'hail-fill',
    'hail-line',
    'hammer-fill',
    'hammer-line',
    'hand-coin-fill',
    'hand-coin-line',
    'hand-heart-fill',
    'hand-heart-line',
    'hand-sanitizer-fill',
    'hand-sanitizer-line',
    'handbag-fill',
    'handbag-line',
    'hard-drive-2-fill',
    'hard-drive-2-line',
    'hard-drive-fill',
    'hard-drive-line',
    'hashtag',
    'haze-2-fill',
    'haze-2-line',
    'haze-fill',
    'haze-line',
    'hd-fill',
    'hd-line',
    'heading',
    'headphone-fill',
    'headphone-line',
    'health-book-fill',
    'health-book-line',
    'heart-2-fill',
    'heart-2-line',
    'heart-3-fill',
    'heart-3-line',
    'heart-add-fill',
    'heart-add-line',
    'heart-fill',
    'heart-line',
    'heart-pulse-fill',
    'heart-pulse-line',
    'hearts-fill',
    'hearts-line',
    'heavy-showers-fill',
    'heavy-showers-line',
    'history-fill',
    'history-line',
    'home-2-fill',
    'home-2-line',
    'home-3-fill',
    'home-3-line',
    'home-4-fill',
    'home-4-line',
    'home-5-fill',
    'home-5-line',
    'home-6-fill',
    'home-6-line',
    'home-7-fill',
    'home-7-line',
    'home-8-fill',
    'home-8-line',
    'home-fill',
    'home-gear-fill',
    'home-gear-line',
    'home-heart-fill',
    'home-heart-line',
    'home-line',
    'home-smile-2-fill',
    'home-smile-2-line',
    'home-smile-fill',
    'home-smile-line',
    'home-wifi-fill',
    'home-wifi-line',
    'honor-of-kings-fill',
    'honor-of-kings-line',
    'honour-fill',
    'honour-line',
    'hospital-fill',
    'hospital-line',
    'hotel-bed-fill',
    'hotel-bed-line',
    'hotel-fill',
    'hotel-line',
    'hotspot-fill',
    'hotspot-line',
    'hq-fill',
    'hq-line',
    'html5-fill',
    'html5-line',
    'ie-fill',
    'ie-line',
    'image-2-fill',
    'image-2-line',
    'image-add-fill',
    'image-add-line',
    'image-edit-fill',
    'image-edit-line',
    'image-fill',
    'image-line',
    'inbox-archive-fill',
    'inbox-archive-line',
    'inbox-fill',
    'inbox-line',
    'inbox-unarchive-fill',
    'inbox-unarchive-line',
    'increase-decrease-fill',
    'increase-decrease-line',
    'indent-decrease',
    'indent-increase',
    'indeterminate-circle-fill',
    'indeterminate-circle-line',
    'information-fill',
    'information-line',
    'infrared-thermometer-fill',
    'infrared-thermometer-line',
    'ink-bottle-fill',
    'ink-bottle-line',
    'input-cursor-move',
    'input-method-fill',
    'input-method-line',
    'insert-column-left',
    'insert-column-right',
    'insert-row-bottom',
    'insert-row-top',
    'instagram-fill',
    'instagram-line',
    'install-fill',
    'install-line',
    'invision-fill',
    'invision-line',
    'italic',
    'kakao-talk-fill',
    'kakao-talk-line',
    'key-2-fill',
    'key-2-line',
    'key-fill',
    'key-line',
    'keyboard-box-fill',
    'keyboard-box-line',
    'keyboard-fill',
    'keyboard-line',
    'keynote-fill',
    'keynote-line',
    'knife-blood-fill',
    'knife-blood-line',
    'knife-fill',
    'knife-line',
    'landscape-fill',
    'landscape-line',
    'layout-2-fill',
    'layout-2-line',
    'layout-3-fill',
    'layout-3-line',
    'layout-4-fill',
    'layout-4-line',
    'layout-5-fill',
    'layout-5-line',
    'layout-6-fill',
    'layout-6-line',
    'layout-bottom-2-fill',
    'layout-bottom-2-line',
    'layout-bottom-fill',
    'layout-bottom-line',
    'layout-column-fill',
    'layout-column-line',
    'layout-fill',
    'layout-grid-fill',
    'layout-grid-line',
    'layout-left-2-fill',
    'layout-left-2-line',
    'layout-left-fill',
    'layout-left-line',
    'layout-line',
    'layout-masonry-fill',
    'layout-masonry-line',
    'layout-right-2-fill',
    'layout-right-2-line',
    'layout-right-fill',
    'layout-right-line',
    'layout-row-fill',
    'layout-row-line',
    'layout-top-2-fill',
    'layout-top-2-line',
    'layout-top-fill',
    'layout-top-line',
    'leaf-fill',
    'leaf-line',
    'lifebuoy-fill',
    'lifebuoy-line',
    'lightbulb-fill',
    'lightbulb-flash-fill',
    'lightbulb-flash-line',
    'lightbulb-line',
    'line-chart-fill',
    'line-chart-line',
    'line-fill',
    'line-height',
    'line-line',
    'link-m',
    'link-unlink-m',
    'link-unlink',
    'link',
    'linkedin-box-fill',
    'linkedin-box-line',
    'linkedin-fill',
    'linkedin-line',
    'links-fill',
    'links-line',
    'list-check-2',
    'list-check',
    'list-ordered',
    'list-settings-fill',
    'list-settings-line',
    'list-unordered',
    'live-fill',
    'live-line',
    'loader-2-fill',
    'loader-2-line',
    'loader-3-fill',
    'loader-3-line',
    'loader-4-fill',
    'loader-4-line',
    'loader-5-fill',
    'loader-5-line',
    'loader-fill',
    'loader-line',
    'lock-2-fill',
    'lock-2-line',
    'lock-fill',
    'lock-line',
    'lock-password-fill',
    'lock-password-line',
    'lock-unlock-fill',
    'lock-unlock-line',
    'login-box-fill',
    'login-box-line',
    'login-circle-fill',
    'login-circle-line',
    'logout-box-fill',
    'logout-box-line',
    'logout-box-r-fill',
    'logout-box-r-line',
    'logout-circle-fill',
    'logout-circle-line',
    'logout-circle-r-fill',
    'logout-circle-r-line',
    'luggage-cart-fill',
    'luggage-cart-line',
    'luggage-deposit-fill',
    'luggage-deposit-line',
    'lungs-fill',
    'lungs-line',
    'mac-fill',
    'mac-line',
    'macbook-fill',
    'macbook-line',
    'magic-fill',
    'magic-line',
    'mail-add-fill',
    'mail-add-line',
    'mail-check-fill',
    'mail-check-line',
    'mail-close-fill',
    'mail-close-line',
    'mail-download-fill',
    'mail-download-line',
    'mail-fill',
    'mail-forbid-fill',
    'mail-forbid-line',
    'mail-line',
    'mail-lock-fill',
    'mail-lock-line',
    'mail-open-fill',
    'mail-open-line',
    'mail-send-fill',
    'mail-send-line',
    'mail-settings-fill',
    'mail-settings-line',
    'mail-star-fill',
    'mail-star-line',
    'mail-unread-fill',
    'mail-unread-line',
    'mail-volume-fill',
    'mail-volume-line',
    'map-2-fill',
    'map-2-line',
    'map-fill',
    'map-line',
    'map-pin-2-fill',
    'map-pin-2-line',
    'map-pin-3-fill',
    'map-pin-3-line',
    'map-pin-4-fill',
    'map-pin-4-line',
    'map-pin-5-fill',
    'map-pin-5-line',
    'map-pin-add-fill',
    'map-pin-add-line',
    'map-pin-fill',
    'map-pin-line',
    'map-pin-range-fill',
    'map-pin-range-line',
    'map-pin-time-fill',
    'map-pin-time-line',
    'map-pin-user-fill',
    'map-pin-user-line',
    'mark-pen-fill',
    'mark-pen-line',
    'markdown-fill',
    'markdown-line',
    'markup-fill',
    'markup-line',
    'mastercard-fill',
    'mastercard-line',
    'mastodon-fill',
    'mastodon-line',
    'medal-2-fill',
    'medal-2-line',
    'medal-fill',
    'medal-line',
    'medicine-bottle-fill',
    'medicine-bottle-line',
    'medium-fill',
    'medium-line',
    'men-fill',
    'men-line',
    'mental-health-fill',
    'mental-health-line',
    'menu-2-fill',
    'menu-2-line',
    'menu-3-fill',
    'menu-3-line',
    'menu-4-fill',
    'menu-4-line',
    'menu-5-fill',
    'menu-5-line',
    'menu-add-fill',
    'menu-add-line',
    'menu-fill',
    'menu-fold-fill',
    'menu-fold-line',
    'menu-line',
    'menu-unfold-fill',
    'menu-unfold-line',
    'merge-cells-horizontal',
    'merge-cells-vertical',
    'message-2-fill',
    'message-2-line',
    'message-3-fill',
    'message-3-line',
    'message-fill',
    'message-line',
    'messenger-fill',
    'messenger-line',
    'meteor-fill',
    'meteor-line',
    'mic-2-fill',
    'mic-2-line',
    'mic-fill',
    'mic-line',
    'mic-off-fill',
    'mic-off-line',
    'mickey-fill',
    'mickey-line',
    'microscope-fill',
    'microscope-line',
    'microsoft-fill',
    'microsoft-line',
    'mind-map',
    'mini-program-fill',
    'mini-program-line',
    'mist-fill',
    'mist-line',
    'money-cny-box-fill',
    'money-cny-box-line',
    'money-cny-circle-fill',
    'money-cny-circle-line',
    'money-dollar-box-fill',
    'money-dollar-box-line',
    'money-dollar-circle-fill',
    'money-dollar-circle-line',
    'money-euro-box-fill',
    'money-euro-box-line',
    'money-euro-circle-fill',
    'money-euro-circle-line',
    'money-pound-box-fill',
    'money-pound-box-line',
    'money-pound-circle-fill',
    'money-pound-circle-line',
    'moon-clear-fill',
    'moon-clear-line',
    'moon-cloudy-fill',
    'moon-cloudy-line',
    'moon-fill',
    'moon-foggy-fill',
    'moon-foggy-line',
    'moon-line',
    'more-2-fill',
    'more-2-line',
    'more-fill',
    'more-line',
    'motorbike-fill',
    'motorbike-line',
    'mouse-fill',
    'mouse-line',
    'movie-2-fill',
    'movie-2-line',
    'movie-fill',
    'movie-line',
    'music-2-fill',
    'music-2-line',
    'music-fill',
    'music-line',
    'mv-fill',
    'mv-line',
    'navigation-fill',
    'navigation-line',
    'netease-cloud-music-fill',
    'netease-cloud-music-line',
    'netflix-fill',
    'netflix-line',
    'newspaper-fill',
    'newspaper-line',
    'node-tree',
    'notification-2-fill',
    'notification-2-line',
    'notification-3-fill',
    'notification-3-line',
    'notification-4-fill',
    'notification-4-line',
    'notification-badge-fill',
    'notification-badge-line',
    'notification-fill',
    'notification-line',
    'notification-off-fill',
    'notification-off-line',
    'npmjs-fill',
    'npmjs-line',
    'number-0',
    'number-1',
    'number-2',
    'number-3',
    'number-4',
    'number-5',
    'number-6',
    'number-7',
    'number-8',
    'number-9',
    'numbers-fill',
    'numbers-line',
    'nurse-fill',
    'nurse-line',
    'oil-fill',
    'oil-line',
    'omega',
    'open-arm-fill',
    'open-arm-line',
    'open-source-fill',
    'open-source-line',
    'opera-fill',
    'opera-line',
    'order-play-fill',
    'order-play-line',
    'organization-chart',
    'outlet-2-fill',
    'outlet-2-line',
    'outlet-fill',
    'outlet-line',
    'page-separator',
    'pages-fill',
    'pages-line',
    'paint-brush-fill',
    'paint-brush-line',
    'paint-fill',
    'paint-line',
    'palette-fill',
    'palette-line',
    'pantone-fill',
    'pantone-line',
    'paragraph',
    'parent-fill',
    'parent-line',
    'parentheses-fill',
    'parentheses-line',
    'parking-box-fill',
    'parking-box-line',
    'parking-fill',
    'parking-line',
    'passport-fill',
    'passport-line',
    'patreon-fill',
    'patreon-line',
    'pause-circle-fill',
    'pause-circle-line',
    'pause-fill',
    'pause-line',
    'pause-mini-fill',
    'pause-mini-line',
    'paypal-fill',
    'paypal-line',
    'pen-nib-fill',
    'pen-nib-line',
    'pencil-fill',
    'pencil-line',
    'pencil-ruler-2-fill',
    'pencil-ruler-2-line',
    'pencil-ruler-fill',
    'pencil-ruler-line',
    'percent-fill',
    'percent-line',
    'phone-camera-fill',
    'phone-camera-line',
    'phone-fill',
    'phone-find-fill',
    'phone-find-line',
    'phone-line',
    'phone-lock-fill',
    'phone-lock-line',
    'picture-in-picture-2-fill',
    'picture-in-picture-2-line',
    'picture-in-picture-exit-fill',
    'picture-in-picture-exit-line',
    'picture-in-picture-fill',
    'picture-in-picture-line',
    'pie-chart-2-fill',
    'pie-chart-2-line',
    'pie-chart-box-fill',
    'pie-chart-box-line',
    'pie-chart-fill',
    'pie-chart-line',
    'pin-distance-fill',
    'pin-distance-line',
    'ping-pong-fill',
    'ping-pong-line',
    'pinterest-fill',
    'pinterest-line',
    'pinyin-input',
    'pixelfed-fill',
    'pixelfed-line',
    'plane-fill',
    'plane-line',
    'plant-fill',
    'plant-line',
    'play-circle-fill',
    'play-circle-line',
    'play-fill',
    'play-line',
    'play-list-2-fill',
    'play-list-2-line',
    'play-list-add-fill',
    'play-list-add-line',
    'play-list-fill',
    'play-list-line',
    'play-mini-fill',
    'play-mini-line',
    'playstation-fill',
    'playstation-line',
    'plug-2-fill',
    'plug-2-line',
    'plug-fill',
    'plug-line',
    'polaroid-2-fill',
    'polaroid-2-line',
    'polaroid-fill',
    'polaroid-line',
    'police-car-fill',
    'police-car-line',
    'price-tag-2-fill',
    'price-tag-2-line',
    'price-tag-3-fill',
    'price-tag-3-line',
    'price-tag-fill',
    'price-tag-line',
    'printer-cloud-fill',
    'printer-cloud-line',
    'printer-fill',
    'printer-line',
    'product-hunt-fill',
    'product-hunt-line',
    'profile-fill',
    'profile-line',
    'projector-2-fill',
    'projector-2-line',
    'projector-fill',
    'projector-line',
    'psychotherapy-fill',
    'psychotherapy-line',
    'pulse-fill',
    'pulse-line',
    'pushpin-2-fill',
    'pushpin-2-line',
    'pushpin-fill',
    'pushpin-line',
    'qq-fill',
    'qq-line',
    'qr-code-fill',
    'qr-code-line',
    'qr-scan-2-fill',
    'qr-scan-2-line',
    'qr-scan-fill',
    'qr-scan-line',
    'question-answer-fill',
    'question-answer-line',
    'question-fill',
    'question-line',
    'question-mark',
    'questionnaire-fill',
    'questionnaire-line',
    'quill-pen-fill',
    'quill-pen-line',
    'radar-fill',
    'radar-line',
    'radio-2-fill',
    'radio-2-line',
    'radio-button-fill',
    'radio-button-line',
    'radio-fill',
    'radio-line',
    'rainbow-fill',
    'rainbow-line',
    'rainy-fill',
    'rainy-line',
    'reactjs-fill',
    'reactjs-line',
    'record-circle-fill',
    'record-circle-line',
    'record-mail-fill',
    'record-mail-line',
    'recycle-fill',
    'recycle-line',
    'red-packet-fill',
    'red-packet-line',
    'reddit-fill',
    'reddit-line',
    'refresh-fill',
    'refresh-line',
    'refund-2-fill',
    'refund-2-line',
    'refund-fill',
    'refund-line',
    'registered-fill',
    'registered-line',
    'remixicon-fill',
    'remixicon-line',
    'remote-control-2-fill',
    'remote-control-2-line',
    'remote-control-fill',
    'remote-control-line',
    'repeat-2-fill',
    'repeat-2-line',
    'repeat-fill',
    'repeat-line',
    'repeat-one-fill',
    'repeat-one-line',
    'reply-all-fill',
    'reply-all-line',
    'reply-fill',
    'reply-line',
    'reserved-fill',
    'reserved-line',
    'rest-time-fill',
    'rest-time-line',
    'restart-fill',
    'restart-line',
    'restaurant-2-fill',
    'restaurant-2-line',
    'restaurant-fill',
    'restaurant-line',
    'rewind-fill',
    'rewind-line',
    'rewind-mini-fill',
    'rewind-mini-line',
    'rhythm-fill',
    'rhythm-line',
    'riding-fill',
    'riding-line',
    'road-map-fill',
    'road-map-line',
    'roadster-fill',
    'roadster-line',
    'robot-fill',
    'robot-line',
    'rocket-2-fill',
    'rocket-2-line',
    'rocket-fill',
    'rocket-line',
    'rotate-lock-fill',
    'rotate-lock-line',
    'rounded-corner',
    'route-fill',
    'route-line',
    'router-fill',
    'router-line',
    'rss-fill',
    'rss-line',
    'ruler-2-fill',
    'ruler-2-line',
    'ruler-fill',
    'ruler-line',
    'run-fill',
    'run-line',
    'safari-fill',
    'safari-line',
    'safe-2-fill',
    'safe-2-line',
    'safe-fill',
    'safe-line',
    'sailboat-fill',
    'sailboat-line',
    'save-2-fill',
    'save-2-line',
    'save-3-fill',
    'save-3-line',
    'save-fill',
    'save-line',
    'scales-2-fill',
    'scales-2-line',
    'scales-3-fill',
    'scales-3-line',
    'scales-fill',
    'scales-line',
    'scan-2-fill',
    'scan-2-line',
    'scan-fill',
    'scan-line',
    'scissors-2-fill',
    'scissors-2-line',
    'scissors-cut-fill',
    'scissors-cut-line',
    'scissors-fill',
    'scissors-line',
    'screenshot-2-fill',
    'screenshot-2-line',
    'screenshot-fill',
    'screenshot-line',
    'sd-card-fill',
    'sd-card-line',
    'sd-card-mini-fill',
    'sd-card-mini-line',
    'search-2-fill',
    'search-2-line',
    'search-eye-fill',
    'search-eye-line',
    'search-fill',
    'search-line',
    'secure-payment-fill',
    'secure-payment-line',
    'seedling-fill',
    'seedling-line',
    'send-backward',
    'send-plane-2-fill',
    'send-plane-2-line',
    'send-plane-fill',
    'send-plane-line',
    'send-to-back',
    'sensor-fill',
    'sensor-line',
    'separator',
    'server-fill',
    'server-line',
    'service-fill',
    'service-line',
    'settings-2-fill',
    'settings-2-line',
    'settings-3-fill',
    'settings-3-line',
    'settings-4-fill',
    'settings-4-line',
    'settings-5-fill',
    'settings-5-line',
    'settings-6-fill',
    'settings-6-line',
    'settings-fill',
    'settings-line',
    'shape-2-fill',
    'shape-2-line',
    'shape-fill',
    'shape-line',
    'share-box-fill',
    'share-box-line',
    'share-circle-fill',
    'share-circle-line',
    'share-fill',
    'share-forward-2-fill',
    'share-forward-2-line',
    'share-forward-box-fill',
    'share-forward-box-line',
    'share-forward-fill',
    'share-forward-line',
    'share-line',
    'shield-check-fill',
    'shield-check-line',
    'shield-cross-fill',
    'shield-cross-line',
    'shield-fill',
    'shield-flash-fill',
    'shield-flash-line',
    'shield-keyhole-fill',
    'shield-keyhole-line',
    'shield-line',
    'shield-star-fill',
    'shield-star-line',
    'shield-user-fill',
    'shield-user-line',
    'ship-2-fill',
    'ship-2-line',
    'ship-fill',
    'ship-line',
    'shirt-fill',
    'shirt-line',
    'shopping-bag-2-fill',
    'shopping-bag-2-line',
    'shopping-bag-3-fill',
    'shopping-bag-3-line',
    'shopping-bag-fill',
    'shopping-bag-line',
    'shopping-basket-2-fill',
    'shopping-basket-2-line',
    'shopping-basket-fill',
    'shopping-basket-line',
    'shopping-cart-2-fill',
    'shopping-cart-2-line',
    'shopping-cart-fill',
    'shopping-cart-line',
    'showers-fill',
    'showers-line',
    'shuffle-fill',
    'shuffle-line',
    'shut-down-fill',
    'shut-down-line',
    'side-bar-fill',
    'side-bar-line',
    'signal-tower-fill',
    'signal-tower-line',
    'signal-wifi-1-fill',
    'signal-wifi-1-line',
    'signal-wifi-2-fill',
    'signal-wifi-2-line',
    'signal-wifi-3-fill',
    'signal-wifi-3-line',
    'signal-wifi-error-fill',
    'signal-wifi-error-line',
    'signal-wifi-fill',
    'signal-wifi-line',
    'signal-wifi-off-fill',
    'signal-wifi-off-line',
    'sim-card-2-fill',
    'sim-card-2-line',
    'sim-card-fill',
    'sim-card-line',
    'single-quotes-l',
    'single-quotes-r',
    'sip-fill',
    'sip-line',
    'skip-back-fill',
    'skip-back-line',
    'skip-back-mini-fill',
    'skip-back-mini-line',
    'skip-forward-fill',
    'skip-forward-line',
    'skip-forward-mini-fill',
    'skip-forward-mini-line',
    'skull-2-fill',
    'skull-2-line',
    'skull-fill',
    'skull-line',
    'skype-fill',
    'skype-line',
    'slack-fill',
    'slack-line',
    'slice-fill',
    'slice-line',
    'slideshow-2-fill',
    'slideshow-2-line',
    'slideshow-3-fill',
    'slideshow-3-line',
    'slideshow-4-fill',
    'slideshow-4-line',
    'slideshow-fill',
    'slideshow-line',
    'smartphone-fill',
    'smartphone-line',
    'snapchat-fill',
    'snapchat-line',
    'snowy-fill',
    'snowy-line',
    'sort-asc',
    'sort-desc',
    'sound-module-fill',
    'sound-module-line',
    'soundcloud-fill',
    'soundcloud-line',
    'space-ship-fill',
    'space-ship-line',
    'space',
    'spam-2-fill',
    'spam-2-line',
    'spam-3-fill',
    'spam-3-line',
    'spam-fill',
    'spam-line',
    'speaker-2-fill',
    'speaker-2-line',
    'speaker-3-fill',
    'speaker-3-line',
    'speaker-fill',
    'speaker-line',
    'spectrum-fill',
    'spectrum-line',
    'speed-fill',
    'speed-line',
    'speed-mini-fill',
    'speed-mini-line',
    'split-cells-horizontal',
    'split-cells-vertical',
    'spotify-fill',
    'spotify-line',
    'spy-fill',
    'spy-line',
    'stack-fill',
    'stack-line',
    'stack-overflow-fill',
    'stack-overflow-line',
    'stackshare-fill',
    'stackshare-line',
    'star-fill',
    'star-half-fill',
    'star-half-line',
    'star-half-s-fill',
    'star-half-s-line',
    'star-line',
    'star-s-fill',
    'star-s-line',
    'star-smile-fill',
    'star-smile-line',
    'steam-fill',
    'steam-line',
    'steering-2-fill',
    'steering-2-line',
    'steering-fill',
    'steering-line',
    'stethoscope-fill',
    'stethoscope-line',
    'sticky-note-2-fill',
    'sticky-note-2-line',
    'sticky-note-fill',
    'sticky-note-line',
    'stock-fill',
    'stock-line',
    'stop-circle-fill',
    'stop-circle-line',
    'stop-fill',
    'stop-line',
    'stop-mini-fill',
    'stop-mini-line',
    'store-2-fill',
    'store-2-line',
    'store-3-fill',
    'store-3-line',
    'store-fill',
    'store-line',
    'strikethrough-2',
    'strikethrough',
    'subscript-2',
    'subscript',
    'subtract-fill',
    'subtract-line',
    'subway-fill',
    'subway-line',
    'subway-wifi-fill',
    'subway-wifi-line',
    'suitcase-2-fill',
    'suitcase-2-line',
    'suitcase-3-fill',
    'suitcase-3-line',
    'suitcase-fill',
    'suitcase-line',
    'sun-cloudy-fill',
    'sun-cloudy-line',
    'sun-fill',
    'sun-foggy-fill',
    'sun-foggy-line',
    'sun-line',
    'superscript-2',
    'superscript',
    'surgical-mask-fill',
    'surgical-mask-line',
    'surround-sound-fill',
    'surround-sound-line',
    'survey-fill',
    'survey-line',
    'swap-box-fill',
    'swap-box-line',
    'swap-fill',
    'swap-line',
    'switch-fill',
    'switch-line',
    'sword-fill',
    'sword-line',
    'syringe-fill',
    'syringe-line',
    't-box-fill',
    't-box-line',
    't-shirt-2-fill',
    't-shirt-2-line',
    't-shirt-air-fill',
    't-shirt-air-line',
    't-shirt-fill',
    't-shirt-line',
    'table-2',
    'table-alt-fill',
    'table-alt-line',
    'table-fill',
    'table-line',
    'tablet-fill',
    'tablet-line',
    'takeaway-fill',
    'takeaway-line',
    'taobao-fill',
    'taobao-line',
    'tape-fill',
    'tape-line',
    'task-fill',
    'task-line',
    'taxi-fill',
    'taxi-line',
    'taxi-wifi-fill',
    'taxi-wifi-line',
    'team-fill',
    'team-line',
    'telegram-fill',
    'telegram-line',
    'temp-cold-fill',
    'temp-cold-line',
    'temp-hot-fill',
    'temp-hot-line',
    'terminal-box-fill',
    'terminal-box-line',
    'terminal-fill',
    'terminal-line',
    'terminal-window-fill',
    'terminal-window-line',
    'test-tube-fill',
    'test-tube-line',
    'text-direction-l',
    'text-direction-r',
    'text-spacing',
    'text-wrap',
    'text',
    'thermometer-fill',
    'thermometer-line',
    'thumb-down-fill',
    'thumb-down-line',
    'thumb-up-fill',
    'thumb-up-line',
    'thunderstorms-fill',
    'thunderstorms-line',
    'ticket-2-fill',
    'ticket-2-line',
    'ticket-fill',
    'ticket-line',
    'time-fill',
    'time-line',
    'timer-2-fill',
    'timer-2-line',
    'timer-fill',
    'timer-flash-fill',
    'timer-flash-line',
    'timer-line',
    'todo-fill',
    'todo-line',
    'toggle-fill',
    'toggle-line',
    'tools-fill',
    'tools-line',
    'tornado-fill',
    'tornado-line',
    'trademark-fill',
    'trademark-line',
    'traffic-light-fill',
    'traffic-light-line',
    'train-fill',
    'train-line',
    'train-wifi-fill',
    'train-wifi-line',
    'translate-2',
    'translate',
    'travesti-fill',
    'travesti-line',
    'treasure-map-fill',
    'treasure-map-line',
    'trello-fill',
    'trello-line',
    'trophy-fill',
    'trophy-line',
    'truck-fill',
    'truck-line',
    'tumblr-fill',
    'tumblr-line',
    'tv-2-fill',
    'tv-2-line',
    'tv-fill',
    'tv-line',
    'twitch-fill',
    'twitch-line',
    'twitter-fill',
    'twitter-line',
    'typhoon-fill',
    'typhoon-line',
    'u-disk-fill',
    'u-disk-line',
    'ubuntu-fill',
    'ubuntu-line',
    'umbrella-fill',
    'umbrella-line',
    'underline',
    'uninstall-fill',
    'uninstall-line',
    'unsplash-fill',
    'unsplash-line',
    'upload-2-fill',
    'upload-2-line',
    'upload-cloud-2-fill',
    'upload-cloud-2-line',
    'upload-cloud-fill',
    'upload-cloud-line',
    'upload-fill',
    'upload-line',
    'usb-fill',
    'usb-line',
    'user-2-fill',
    'user-2-line',
    'user-3-fill',
    'user-3-line',
    'user-4-fill',
    'user-4-line',
    'user-5-fill',
    'user-5-line',
    'user-6-fill',
    'user-6-line',
    'user-add-fill',
    'user-add-line',
    'user-fill',
    'user-follow-fill',
    'user-follow-line',
    'user-heart-fill',
    'user-heart-line',
    'user-line',
    'user-location-fill',
    'user-location-line',
    'user-received-2-fill',
    'user-received-2-line',
    'user-received-fill',
    'user-received-line',
    'user-search-fill',
    'user-search-line',
    'user-settings-fill',
    'user-settings-line',
    'user-shared-2-fill',
    'user-shared-2-line',
    'user-shared-fill',
    'user-shared-line',
    'user-smile-fill',
    'user-smile-line',
    'user-star-fill',
    'user-star-line',
    'user-unfollow-fill',
    'user-unfollow-line',
    'user-voice-fill',
    'user-voice-line',
    'video-add-fill',
    'video-add-line',
    'video-chat-fill',
    'video-chat-line',
    'video-download-fill',
    'video-download-line',
    'video-fill',
    'video-line',
    'video-upload-fill',
    'video-upload-line',
    'vidicon-2-fill',
    'vidicon-2-line',
    'vidicon-fill',
    'vidicon-line',
    'vimeo-fill',
    'vimeo-line',
    'vip-crown-2-fill',
    'vip-crown-2-line',
    'vip-crown-fill',
    'vip-crown-line',
    'vip-diamond-fill',
    'vip-diamond-line',
    'vip-fill',
    'vip-line',
    'virus-fill',
    'virus-line',
    'visa-fill',
    'visa-line',
    'voice-recognition-fill',
    'voice-recognition-line',
    'voiceprint-fill',
    'voiceprint-line',
    'volume-down-fill',
    'volume-down-line',
    'volume-mute-fill',
    'volume-mute-line',
    'volume-off-vibrate-fill',
    'volume-off-vibrate-line',
    'volume-up-fill',
    'volume-up-line',
    'volume-vibrate-fill',
    'volume-vibrate-line',
    'vuejs-fill',
    'vuejs-line',
    'walk-fill',
    'walk-line',
    'wallet-2-fill',
    'wallet-2-line',
    'wallet-3-fill',
    'wallet-3-line',
    'wallet-fill',
    'wallet-line',
    'water-flash-fill',
    'water-flash-line',
    'webcam-fill',
    'webcam-line',
    'wechat-2-fill',
    'wechat-2-line',
    'wechat-fill',
    'wechat-line',
    'wechat-pay-fill',
    'wechat-pay-line',
    'weibo-fill',
    'weibo-line',
    'whatsapp-fill',
    'whatsapp-line',
    'wheelchair-fill',
    'wheelchair-line',
    'wifi-fill',
    'wifi-line',
    'wifi-off-fill',
    'wifi-off-line',
    'window-2-fill',
    'window-2-line',
    'window-fill',
    'window-line',
    'windows-fill',
    'windows-line',
    'windy-fill',
    'windy-line',
    'wireless-charging-fill',
    'wireless-charging-line',
    'women-fill',
    'women-line',
    'wubi-input',
    'xbox-fill',
    'xbox-line',
    'xing-fill',
    'xing-line',
    'youtube-fill',
    'youtube-line',
    'zcool-fill',
    'zcool-line',
    'zhihu-fill',
    'zhihu-line',
    'zoom-in-fill',
    'zoom-in-line',
    'zoom-out-fill',
    'zoom-out-line',
    'zzz-fill',
    'zzz-line',
  ]

  return new Promise((resolve, reject) => {
    resolve({ data: List, code: 200, msg: '操作成功' })
  })
}
